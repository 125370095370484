<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '30px 5px 5px 5px'}">
    <div style="display: flex; justify-content: space-between; align-items: center; background-color: white;margin-bottom: 5px; padding-left: 20px; padding-right: 10px; width: 100%;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '16px' : '20px'}" style="font-weight: 700;">{{'Select a Payment Method' | translate}}</label><button (click)="closeBottomSheet()" [mat-dialog-close]="true" mat-icon-button aria-label="back"><i class="bi bi-x"></i></button>
    </div>
</h2>
  

<div mat-dialog-content style="text-align: center;">
    <app-loading *ngIf="isLoading == false; else hideLoading"></app-loading>
    <ng-template #hideLoading>
        <div *ngFor="let row of dataModel;let indexArr = index;" [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-bottom: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;">
            <!-- <div *ngFor="let rowTarif of row.data.tarif;let indexTarif = index;" [ngStyle]="{'padding': appServiceScreenMobileSmall ? '10px' : '15px'}" style="display: flex;justify-content: space-between;align-items: center;background-color: white;margin-bottom: 20px;width: 100%;border: 1px solid #DDDDDD;border-radius: 12px;"> -->
                
                <div style="display: flex; align-items: center; flex-grow: 1;">
                    <!-- LOGO -->
                    <div style="border: 1px solid #DDDDDD; border-radius: 8px; padding: 10px;">
                        <img alt="chatfoto" ngSrc="{{row.image_url}}" width="40" height="40">
                    </div>
                
                    <!-- Nama Payment Method -->
                    <div style="margin-left: 10px; text-align: left; min-width: 0; flex: 1;">
                        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" 
                               style="font-weight: 700; display: block; text-align: left;">
                            {{row.name}}
                        </label>
                    </div>
                </div>
                <!-- <mat-radio-button (change)="selectedPayment(dataModel[indexArr])"></mat-radio-button> -->
                <mat-radio-button (change)="selectedPayment(row)" [value]="row.id" [checked]="setPayment?.id === row.id"></mat-radio-button>
            <!-- </div> -->
        </div>
    </ng-template>
</div>

<mat-dialog-actions [ngStyle]="{'display': appServiceScreenMobile ? 'flex' : '', 'align-items': appServiceScreenMobile ? 'center' : ''}">
   
    <div class="col-12" [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '0px 0px'}" style="text-align: end;">
        <button (click)="setRecipientTemp()" 
                [disabled]="!setPayment" 
                [ngStyle]="{'background-color': setPayment ? '#0e8667' : '#DDDDDD', 
                'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" 
                style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" 
                mat-button>
                {{'Continue' | translate}}
        </button>

        <!-- <button (click)="setRecipientTemp()" disabled="{{setPayment != undefined ? false : true}}" [ngStyle]="{'background-color': setPayment != undefined ? '#0e8667' : '#DDDDDD', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}" style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" mat-button>{{'Continue' | translate}}</button> -->
        <!-- <button (click)="setRecipientTemp()"
                [ngStyle]="{'background-color': isContinueDisabled ? '#DDDDDD' : '#0e8667', 'font-size': appServiceScreenMobileSmall ? '14px' : '16px'}"
                style="width: 100%;border-radius: 8px;font-weight: 700;color: white;" mat-button>
                {{'Continue' | translate}}
        </button> -->
    </div>
</mat-dialog-actions>
