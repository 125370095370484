<h2 [ngStyle]="{'padding': appServiceScreenMobile ? '0px' : '20px 20px 0px 20px'}">
    <div style="display: flex; justify-content: space-between; align-items: center;">
        <label [ngStyle]="{'font-size': appServiceScreenMobileSmall ? '18px' : '20px'}" style="font-weight: 700;">OVO</label>
        <button mat-icon-button (click)="closeDialog()" mat-dialog-close aria-label="Close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</h2>


<form [formGroup]="form" (ngSubmit)="savePhoneNumber()" class="dialog-container">
    <div mat-dialog-content class="dialog-content">
        <label class="form-label">Enter Phone Number for OVO Payment</label>
        <input type="tel" formControlName="phoneNumber" placeholder="" class="form-control" style="padding-left: 12px;">
    </div>

    <mat-dialog-actions class="dialog-actions">
        <button type="submit" [disabled]="!form.valid" class="save-button" mat-button>
            Save
        </button>
    </mat-dialog-actions>
</form>