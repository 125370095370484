import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { RecipientComponent } from '../dialog/recipient/recipient.component';
import { AddressComponent } from '../dialog/address/address.component';
import { DeliveryComponent } from '../dialog/delivery/delivery.component';
import { PaymentNewComponent } from '../dialog/payment-new/payment-new.component'; // load payment-new component
import { OrderConfirmationComponent } from '../dialog/order-confirmation/order-confirmation.component';
import { VoucherComponent } from '../dialog/voucher/voucher.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeletedComponent } from '../dialog/deleted/deleted.component';
import { AppService } from 'src/app/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService as ApiCart } from 'src/app/services/cart/api.service';
import { CartModel } from 'src/app/models/cart-model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ApiService as ApiPhoto } from 'src/app/services/photo/api.service';
import { ListPhotoComponent } from '../photo/list-photo/list-photo.component';
import { ProgressbarDialogComponent } from '../../loading/progressbar-dialog/progressbar-dialog.component';
import { LoadingDialogComponent } from '../../loading/loading-dialog/loading-dialog.component';

import { OvoPhoneDialogComponent } from '../dialog/ovo-phone-dialog/ovo-phone-dialog.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  isLoading: boolean = true;
  data!: CartModel.Results;
  dataCart!: CartModel.MyCart[];
  dataRecipient!: CartModel.Recipient;
  addressId!: number;
  isUpload: boolean = false;
  myFiles:Array<any> = [];
  progress: number = 0;
  listPhoto!: ListPhotoComponent;

  // Untuk reset payment method jika user logout
  ngOnInit() {
    // Check if savedPhoneNumber exists
    const savedPhoneNumber = localStorage.getItem('savedPhoneNumber');
    if (!savedPhoneNumber) {
      this.resetPaymentMethod();
    }
  }

  isVoucherApplied: boolean = false;
  disableShippingMethod: boolean = false;
  validVoucherCode: string = 'CETAKLANGSUNG'; // Contoh kode voucher yang ingin digunakan

  constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet, private translate: TranslateService, private apiCart: ApiCart, private router: Router, private snackBar: MatSnackBar, private apiPhoto: ApiPhoto, private cd: ChangeDetectorRef, public appService: AppService) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    this.appService.setIsPrintMobile(false)
    this.appService.setIsFooterMobileBottom('')

    if(localStorage.getItem('lang') == undefined) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang(localStorage.getItem('lang')!);
    }

    Promise.all([this.getCart(), this.getQty()])
  }

  async getCart() {
    (await this.apiCart.getCart())
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.data = v.results
        this.dataCart = v.results.my_cart
        this.dataRecipient = this.data.recipient_and_address.recipient
        this.addressId = this.data.recipient_and_address.address.id
      },
      error: (e) => {
        this.isLoading = false
        if(e.status == 500) {
          this.snackBar.open(e.error.message, '', {
            duration: 3 * 1000,
            panelClass: ['success-snackbar']
          })
        } else {
          if(e.error == 'Unauthorized.')
            localStorage.clear()
            window.location.assign(environment.urlIndex)
        }
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }
  openOvoDialog() {
    const savedNumber = localStorage.getItem('savedPhoneNumber');
    const dialogRef = this.dialog.open(OvoPhoneDialogComponent, {
      width: this.appServiceScreenMobile ? '100%' : '300px',
      maxWidth: '100%',
      height: this.appServiceScreenMobile ? '33%' : 'auto',
      position: this.appServiceScreenMobile ? { bottom: '0px' } : undefined,
      disableClose: true,
      data: { 
        phoneNumber: savedNumber // Pass the saved phone number to dialog
      },
      panelClass: this.appServiceScreenMobile ? 'bottom-sheet-dialog' : 'centered-dialog'
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        localStorage.setItem('savedPhoneNumber', result);
        this.getCart();
      }
    });
  }
  // FUNCTION SHOW PHONE NUMBER INSERTED ON OVO DIALOG BOX
  get savedPhoneNumber(): string | null {
    return localStorage.getItem('savedPhoneNumber');
  }  
  async addQtyCart(cartId: number, newQty: number, photoId: number, isDeleted?: string) {
    if(newQty <= 0) {
      this.callDeleted(photoId!, isDeleted!, 'deleted_pc')
    } else {
      this.dialog.open(LoadingDialogComponent, { disableClose: true })

      var formData: any = new FormData();
      formData.append('cart_id', cartId);
      formData.append('qty', newQty);

      (await this.apiCart.addQtyInCart(formData))
      .subscribe({
        next: (v) => {
          this.dialog.closeAll()
          this.getCart()
        },
        error: (e) => { 
          this.dialog.closeAll()
        },
        complete: () => { 
          this.dialog.closeAll()
        }
      })
    }
  }
  callRecipient() {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(RecipientComponent, { disableClose: true, data: {recipient: this.dataRecipient == null ? undefined : this.dataRecipient.id} })
      .afterDismissed().subscribe(() => {
        this.getCart()
      })
    } else {
      this.dialog.open(RecipientComponent, { disableClose: true, data: {recipient: this.dataRecipient == null ? undefined : this.dataRecipient.id} })
      .afterClosed().subscribe(() => {
        this.getCart()
      })
    }
  }
  callAddress() {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(AddressComponent, { disableClose: true, data: {address: this.addressId} })
      .afterDismissed().subscribe(() => {
        this.getCart()
      })
    } else {
      this.dialog.open(AddressComponent, { disableClose: true, data: {address: this.addressId} })
      .afterClosed().subscribe(() => {
        this.getCart()
      })
    }
  }
  callVoucher() {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(VoucherComponent, { disableClose: true })
      .afterDismissed().subscribe(() => {
        this.getCart()
      })
    } else {
      this.dialog.open(VoucherComponent, { disableClose: true })
      .afterClosed().subscribe(() => {
        this.getCart()
      })
    }
  }
  async removeVoucher() {
    (await this.apiCart.removeVoucher())
    .subscribe({
      next: (v) => {
        this.isLoading = false
        this.getCart()
      },
      error: (e) => {
        this.isLoading = false
      },
      complete: () => {
        this.isLoading = false
      }
    })
  }
  // Fungsi untuk memvalidasi kode voucher
  applyVoucher(voucherCode: string) {
    if (voucherCode === this.validVoucherCode) {
      this.isVoucherApplied = true;
      this.disableShippingMethod = true; // Nonaktifkan shipping method
    } else {
      this.isVoucherApplied = false;
      this.disableShippingMethod = false; // Aktifkan kembali jika kode tidak valid
    }
  }
  callDelivery() {
    if(this.data.recipient_and_address.address != null) {
      if(this.appServiceScreenMobile) {
        this.bottomSheet.open(DeliveryComponent, { disableClose: true, data: {addressId: this.addressId} })
        .afterDismissed().subscribe(() => {
          this.getCart()
        })
      } else {
        this.dialog.open(DeliveryComponent, { disableClose: true, data: {addressId: this.addressId} })
        .afterClosed().subscribe(() => {
          this.getCart()
        })
      }
    } else {
      this.snackBar.open('Address Not Found', '', {
        duration: 3 * 1000,
        panelClass: ['failed-snackbar']
      })
    }
  }
  // Payment New
  callPaymentNew() {
    if(this.data.recipient_and_address.address != null) {
      if(this.appServiceScreenMobile) {
        this.bottomSheet.open(PaymentNewComponent, { disableClose: true, data: {addressId: this.addressId} })
        .afterDismissed().subscribe(() => {
          this.getCart()
        })
      } else {
        this.dialog.open(PaymentNewComponent, { disableClose: true, data: {addressId: this.addressId} })
        .afterClosed().subscribe(() => {
          this.getCart()
        })
      }
    } else {
      this.snackBar.open('Address Not Found', '', {
        duration: 3 * 1000,
        panelClass: ['failed-snackbar']
      })
    }
  }
  // callPaymentNew() {
  //   if (this.data.recipient_and_address.address != null) {
  //     const dialogRef = this.dialog.open(PaymentNewComponent, { 
  //       disableClose: true, 
  //       data: { addressId: this.addressId } 
  //     });
  
  //     dialogRef.afterClosed().subscribe(selectedPaymentMethod => {
  //       if (selectedPaymentMethod === 'OVO') {
  //         // Jika metode pembayaran OVO dipilih, buka dialog input nomor OVO
  //         this.dialog.open(OvoPhoneDialogComponent, {
  //           disableClose: true,
  //           data: {}
  //         }).afterClosed().subscribe(phoneNumber => {
  //           if (phoneNumber) {
  //             console.log('Nomor OVO yang dimasukkan:', phoneNumber);
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     this.snackBar.open('Address Not Found', '', {
  //       duration: 3000,
  //       panelClass: ['failed-snackbar']
  //     });
  //   }
  // }
  callOrder() {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(OrderConfirmationComponent, { disableClose: true })
    } else {
      this.dialog.open(OrderConfirmationComponent, { disableClose: true })
    }
  }
  canProceedToPayment(): boolean {
    const hasValidAddress = this.data.recipient_and_address?.address != null && this.data.recipient_and_address?.recipient != null;
    const isCetakLangsung = this.data.voucher?.is_direct_print === 1;
  
    // Jika voucher is_direct_print = 1, abaikan pengecekan shipping method 
    if (isCetakLangsung) {
      return this.data.my_cart.length > 0 && hasValidAddress;
    }
  
    // Jika voucher is_direct_print bukan 1, pastikan shipping method terpilih
    return this.data.my_cart.length > 0 && hasValidAddress && this.data.delivery_method != null;
  }
  
  
  callDeleted(id: number, isDeleted: string, desc: any, name?: string) {
    if(this.appServiceScreenMobile) {
      this.bottomSheet.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: isDeleted, title: 'Delete_photo', description: desc, name: name}})
    } else {
      this.dialog.open(DeletedComponent, { disableClose: true, data: {id: id, isDelete: isDeleted, title: 'Delete_photo', description: desc, name: name} })
    }
  }
  selectFile(event: any, id: any) {
    this.dialog.open(ProgressbarDialogComponent, { disableClose: true })

    for (var i = 0; i < event.target.files.length; i++) { 
      this.myFiles.push(event.target.files[i])
    }

    const formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) { 
      formData.append("file[]", this.myFiles[i])
    }
    formData.append('photo_size_id', id);

    this.apiPhoto.uploadFoto(formData, false, 'photo/'+id+'/false/true/true')
  }
  async getQty() {
    (await this.apiCart.cartQty())
    .subscribe({
      next: (v) => {
        this.appService.setIsQty(v.total)
      },
      error: (e) => { },
      complete: () => { }
    })
  }
  async selectWhiteBorder(photoId: number, whiteBorder: any) {
    this.dialog.open(LoadingDialogComponent, { disableClose: true })

    var formData: any = new FormData();
    formData.append('photo_id', photoId);
    formData.append('white_border', whiteBorder.checked ? 1 : 0);

    (this.apiPhoto.postWhiteBorder(formData))
    .subscribe({
      next: (v) => {
        this.dialog.closeAll()
      },
      error: (e) => { 
        this.dialog.closeAll()
      },
      complete: () => { 
        this.dialog.closeAll()
      }
    })
  }
  async resetPaymentMethod() {
    (await this.apiCart.resetPaymentId())
    .subscribe({
      next: () => {
        this.getCart(); // Refresh cart setelah reset payment
      },
      error: (e) => {
        console.error('Gagal reset metode pembayaran:', e);
      }
    });
  }
  

}
