import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';  // Tambahkan ini
import { MatIconModule } from '@angular/material/icon';  // Tambahkan ini
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';  

import { Output, EventEmitter } from '@angular/core'; // Tambahkan ini


@Component({
  selector: 'app-ovo-phone-dialog',
  templateUrl: './ovo-phone-dialog.component.html',
  styleUrls: ['./ovo-phone-dialog.component.css'],
  standalone: true, // Jika standalone, perlu impor modul Angular Material
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule, // Tambahkan ini
    MatIconModule,   // Tambahkan ini
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class OvoPhoneDialogComponent {
  phoneNumber: string = '';
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  form!: FormGroup;
  isButton: boolean = true;
  isLoading: boolean = false;
  isButtonDisabled: boolean = true;
  dialog: any;

  @Output() dialogClosed = new EventEmitter<void>(); // Tambahkan ini
  constructor(
      @Inject(Injector) public injector: Injector,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<OvoPhoneDialogComponent>,
      private bottomSheetRef: MatBottomSheetRef<OvoPhoneDialogComponent>,
      private appService: AppService,
      private snackBar: MatSnackBar,
      public fb: FormBuilder,
  ) {
      // Initialize form with saved phone number from localStorage
      const savedPhoneNumber = localStorage.getItem('savedPhoneNumber') || '';
      
      this.form = this.fb.group({
        phoneNumber: [savedPhoneNumber, [Validators.required, Validators.pattern(/^08[0-9]{8,11}$/)]]
      });
  
      // Update tombol saat form berubah
      this.form.valueChanges.subscribe(() => {
        console.log("Form valid:", this.form.valid);
        console.log("Phone number:", this.form.value.phoneNumber);
        this.isButtonDisabled = !this.form.valid;
      });
  }
  
  closeDialog(): void {
    if (this.appServiceScreenMobile) {
      this.bottomSheetRef.dismiss();
    } else {
      this.dialogRef.close(null);
    }
  }

  savePhoneNumber(): void {
    if (this.form.valid) {
      const phoneNumber = this.form.value.phoneNumber;
      localStorage.setItem('savedPhoneNumber', phoneNumber);
  
      this.snackBar.open('Nomor HP berhasil disimpan!', 'Tutup', { duration: 3000 });
  
      if (this.appServiceScreenMobile) {
        this.bottomSheetRef.dismiss(phoneNumber);
      } else {
        this.dialogRef.close(phoneNumber);
      }
  
      // Tambahkan reload setelah menyimpan nomor HP
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }
  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
