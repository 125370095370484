// PAYMENT-NEW TS

import { Component, Inject, Injector } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { PaymentModel } from 'src/app/models/payment-new-model';
import { ApiService as ApiPayment } from 'src/app/services/payment-new/api.service';

import { MatDialog } from '@angular/material/dialog';
import { OvoPhoneDialogComponent } from '../ovo-phone-dialog/ovo-phone-dialog.component';


@Component({
  selector: 'app-payment-new',
  templateUrl: './payment-new.component.html',
  styleUrls: ['./payment-new.component.css']
})

export class PaymentNewComponent {
  destroyed = new Subject<void>();
  appServiceScreenDesktop!: boolean;
  appServiceScreenMobile!: boolean;
  appServiceScreenMobileSmall!: boolean;
  data: any = MAT_DIALOG_DATA;
  dataMobile: any = MAT_BOTTOM_SHEET_DATA;
  isLoading: boolean = true;
  dataModel!: PaymentModel.Result[];
  addressId!: number;
  setPayment!: PaymentModel.Result;
  // setDeliveryTarif!: PaymentModel.Tarif;

  showPhoneInput: boolean = false;
  phoneNumber: string = '';
  isContinueDisabled: boolean = true;

  constructor (
    @Inject(Injector)public injector: Injector,
    private dialogRef: MatDialogRef<PaymentNewComponent>,
    private bottomSheetRef: MatBottomSheetRef<PaymentNewComponent>,
    private ApiPayment: ApiPayment,
    private snackBar: MatSnackBar,
    public appService: AppService,
    private dialog: MatDialog
  ) {
    this.appService.breakpointDesktop$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenDesktop = result
    })

    this.appService.breakpointMobile$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobile = result
    })

    this.appService.breakpointMobileSmall$.pipe(takeUntil(this.destroyed)).subscribe(result => {
      this.appServiceScreenMobileSmall = result
    })

    if (this.appServiceScreenDesktop) {
      this.data = injector.get(MAT_DIALOG_DATA);
      this.addressId = this.data == undefined ? '' : this.data['addressId']
    } else {
      this.dataMobile = injector.get(MAT_BOTTOM_SHEET_DATA);
      this.addressId = this.dataMobile == undefined ? '' : this.dataMobile['addressId']
    }

    this.showData(this.addressId)
  }

  // Set ukuran dialog berdasarkan jenis tampilan
  ngAfterViewInit() {
    if (this.appServiceScreenDesktop) {
      this.dialogRef.updateSize('300px', 'auto'); // Dialog kecil untuk desktop
    }
  }


  showData(addressId: number) {
    this.isLoading = false;
    
    this.ApiPayment.showPayment()
    .subscribe({
      next: (response: any) => {
        this.isLoading = true
        this.dataModel = response.results
      },
      error: (error) => {
        this.isLoading = true
        this.snackBar.open(error.error.message, '', {
          duration: 3 * 1000,
          panelClass: ['failed-snackbar']
        })
      },
    })
  }

  async setRecipientTemp() {
    this.isLoading = false;
    
    // Pastikan ada metode pembayaran yang dipilih
    if (!this.setPayment) {
      this.snackBar.open('Silakan pilih metode pembayaran!', '', {
        duration: 3000,
        panelClass: ['failed-snackbar']
      });
      return;
    }

    if (this.appServiceScreenMobile) {
      const bottomSheetRef = this.bottomSheetRef;
      bottomSheetRef.dismiss();
    } else {
      this.dialogRef.close();
    }
    
    // Jika metode pembayaran adalah OVO, buka dialog input nomor HP
    if (this.setPayment.id === 2) { 
    
      const dialogRef = this.dialog.open(OvoPhoneDialogComponent, {
        width: this.appServiceScreenMobile ? '100%' : '300px', // Mobile 100%, Desktop 300px
        maxWidth: '100%',
        height: this.appServiceScreenMobile ? '33%' : 'auto', // Mobile full screen
        position: this.appServiceScreenMobile ? { bottom: '0px' } : undefined,
        disableClose: true,
        data: { phoneNumber: this.phoneNumber },
        panelClass: this.appServiceScreenMobile ? 'bottom-sheet-dialog' : 'centered-dialog'
      });
      

      dialogRef.afterClosed().subscribe(result => {
        console.log("Dialog result:", result);
        if (result) {
          this.phoneNumber = result;
          // localStorage.setItem('ovoPhoneNumber', this.phoneNumber);
          localStorage.setItem('savedPhoneNumber', this.phoneNumber);
          this.validateContinueButton();
          console.log("Phone number saved:", this.phoneNumber);
          this.processPayment();

          // Tambahkan reload halaman setelah dialog tertutup
          setTimeout(() => {
            window.location.reload();
          }, 200);

        } else {
          this.snackBar.open('Nomor HP diperlukan untuk pembayaran OVO.', '', {
            duration: 3000,
            panelClass: ['failed-snackbar']
          });
        }
      });
      
  
      return; // Hentikan eksekusi, tunggu input user sebelum lanjut
    }
  
    // Jika bukan OVO, lanjutkan proses pembayaran seperti biasa
    this.processPayment();
  }
  
  processPayment() {
    this.ApiPayment.setPayment(this.setPayment.id)
      .subscribe({
        next: (response) => {
          this.isLoading = true;
  
          if (this.appServiceScreenMobile) {
            this.bottomSheetRef.dismiss();
          } else {
            this.dialogRef.close();
          }
        },
        error: (error) => {
          this.isLoading = true;
        },
      });
  }

  selectedPayment(data: PaymentModel.Result) {
    console.log("Payment method selected:", data);
    this.setPayment = data; 
    this.showPhoneInput = data.id === 2;
    this.validateContinueButton();
  }

  validateContinueButton() {
    this.isContinueDisabled = !(this.setPayment && (this.setPayment.id !== 2 || (this.setPayment.id === 2 && this.phoneNumber.length >= 10)));
  }

  closeBottomSheet() {
    if(this.appServiceScreenMobile)
      this.bottomSheetRef.dismiss()
  }


  // Function validate phone number
  validatePhoneNumber(event: any) {
    const pattern = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault(); // <--Mencegah input yang tidak sesuai
    }
  }

}
